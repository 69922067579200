import React, { FC, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { stylesFactory, useTheme, ButtonGroup, LinkButton, VerticalGroup } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';

export const WelcomeBanner: FC = () => {
  const styles = getStyles(useTheme());
  const [org, setOrg] = useState('');

  useEffect(() => {
    getBackendSrv()
      .get('api/org')
      .then((result: any) => {
        setOrg(result.name || '');
      });
  });
  return (
    <div className={styles.container}>
      <VerticalGroup>
        <h1 className={styles.title}>{org}</h1>
        <ButtonGroup>
          <LinkButton href="/d/vessel" icon="graph-bar" title="Vessel Dashboard" variant="secondary" fill="text">
            Vessel
          </LinkButton>
          <LinkButton
            href="/d/consumption"
            icon="graph-bar"
            title="Vessel Consumption Dashboard"
            variant="secondary"
            fill="text"
          >
            Consumption
          </LinkButton>
          <LinkButton href="/d/voyage-list" title="Voyages List" variant="secondary" icon="list-ui-alt" fill="text">
            Voyage
          </LinkButton>
          <LinkButton href="/d/bunker-list" title="Bunkers List" variant="secondary" icon="list-ui-alt" fill="text">
            Bunkering
          </LinkButton>
          <LinkButton
            href="/dashboards?tag=performance"
            title="Performance Dashboards"
            variant="secondary"
            icon="apps"
            fill="text"
          >
            Performance
          </LinkButton>
        </ButtonGroup>
      </VerticalGroup>
      <div className={styles.help}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 40" className={styles.logo}>
          <path
            d="M21.3487 32.2296V28.836L20.3383 30.5954H19.2189L18.2085 28.836V32.2432H16.46V25.113H18.1785L19.7772 28.2532L21.3787 25.113H23.0972V32.2296H21.3487Z"
            id="filled"
          />
          <path
            d="M27.0434 32.2295V31.791C26.8965 31.9432 26.7241 32.0686 26.5341 32.1614C26.3154 32.2495 26.0806 32.2903 25.845 32.2813C25.5944 32.2842 25.3452 32.2417 25.1097 32.156C24.905 32.0767 24.7194 31.9551 24.565 31.7992C24.409 31.6459 24.289 31.4598 24.2136 31.2545C24.1327 31.0282 24.0921 30.7895 24.0938 30.5491C24.0939 30.3491 24.1299 30.1508 24.2 29.9636C24.2693 29.7739 24.3822 29.6032 24.5296 29.4651C24.694 29.3125 24.8876 29.1949 25.0988 29.1193C25.3613 29.0281 25.638 28.9848 25.9158 28.9913H27.0053V28.836C27.0151 28.7393 27.0031 28.6416 26.9701 28.5501C26.9371 28.4586 26.884 28.3758 26.8146 28.3076C26.6296 28.1911 26.4114 28.1385 26.1936 28.1579C26.0273 28.1487 25.861 28.1757 25.7061 28.2368C25.5684 28.3065 25.4433 28.3985 25.3357 28.5092L24.3171 27.4933C24.442 27.3661 24.5788 27.2512 24.7257 27.1502C24.862 27.0609 25.0082 26.9878 25.1614 26.9323C25.3246 26.8751 25.4936 26.8359 25.6653 26.8152C25.8676 26.7898 26.0714 26.7779 26.2754 26.7798C27.0461 26.7798 27.6371 26.9377 28.032 27.2591C28.24 27.4398 28.4027 27.6667 28.5072 27.9216C28.6118 28.1765 28.6551 28.4524 28.6339 28.7271V32.2159L27.0434 32.2295ZM27.0053 30.0725H26.1882C26.0514 30.0634 25.9162 30.1062 25.8096 30.1923C25.7639 30.2292 25.7273 30.2762 25.7028 30.3296C25.6782 30.383 25.6663 30.4413 25.668 30.5001C25.6681 30.5592 25.6802 30.6177 25.7036 30.6721C25.727 30.7264 25.7612 30.7754 25.8042 30.816C25.8588 30.8646 25.9226 30.9017 25.9919 30.9251C26.0611 30.9485 26.1344 30.9577 26.2073 30.9522C26.3335 30.9548 26.4596 30.9429 26.5831 30.9168C26.686 30.8843 26.7795 30.8273 26.8555 30.7507C26.9173 30.6752 26.9634 30.5881 26.991 30.4946C27.0186 30.401 27.0271 30.3029 27.0162 30.2059L27.0053 30.0725Z"
            id="filled"
          />
          <path
            d="M32.6703 28.5637C32.5948 28.4871 32.5108 28.4195 32.4198 28.3621C32.3154 28.3058 32.1979 28.2785 32.0793 28.2832C32.013 28.2856 31.9472 28.2956 31.8832 28.3131C31.804 28.3343 31.7299 28.3714 31.6654 28.4221C31.5904 28.4828 31.5307 28.5602 31.4911 28.6481C31.4381 28.766 31.4138 28.8948 31.4202 29.024V32.2404H29.7861V26.8533H31.374V27.2918C31.5259 27.1511 31.6998 27.0361 31.8887 26.9514C32.1252 26.843 32.383 26.789 32.6431 26.7934C32.8775 26.7877 33.111 26.8246 33.3322 26.9023C33.5477 26.9912 33.7424 27.1238 33.9041 27.2918L32.6703 28.5637Z"
            id="filled"
          />
          <path
            d="M34.541 26.3031V25.053H36.1751V26.3031H34.541ZM34.541 32.2295V27.0548H36.1751V32.2295H34.541Z"
            id="filled"
          />
          <path
            d="M40.3782 32.2295V29.0131C40.383 28.8841 40.3588 28.7556 40.3074 28.6372C40.2668 28.5499 40.2072 28.4727 40.1331 28.4112C40.0666 28.3604 39.9907 28.3233 39.9098 28.3022C39.7821 28.2622 39.6453 28.2622 39.5176 28.3022C39.4304 28.3246 39.3494 28.3663 39.2806 28.4243C39.2117 28.4823 39.1568 28.5551 39.12 28.6372C39.0686 28.7556 39.0444 28.8841 39.0492 29.0131V32.2295H37.415V26.8533H39.011V27.2918C39.1797 27.1286 39.3825 27.0049 39.6048 26.9296C39.8142 26.8486 40.0366 26.8062 40.2611 26.8043C40.4946 26.7997 40.7267 26.8404 40.9447 26.9241C41.142 26.9993 41.3211 27.1153 41.4704 27.2645C41.6814 27.4634 41.8348 27.7156 41.9143 27.9945C41.9785 28.262 42.0096 28.5364 42.0069 28.8115V32.2486L40.3782 32.2295Z"
            id="filled"
          />
          <path
            d="M44.5669 30.0725C44.567 30.2942 44.6552 30.5068 44.8121 30.6635C44.9035 30.7556 45.0134 30.8275 45.1344 30.8744C45.2555 30.9213 45.385 30.9423 45.5147 30.9359C45.7413 30.9466 45.9674 30.9066 46.1765 30.8188C46.3433 30.732 46.4951 30.6188 46.6259 30.4838L47.6064 31.4615C47.4746 31.5923 47.3335 31.7134 47.1842 31.8237C47.0424 31.93 46.89 32.0213 46.7294 32.0961C46.5554 32.1731 46.3724 32.228 46.1847 32.2595C45.9636 32.2986 45.7393 32.3168 45.5147 32.314C45.2113 32.3131 44.909 32.2784 44.6132 32.2105C44.3139 32.1412 44.0344 32.0042 43.7962 31.8101C43.5326 31.5849 43.3278 31.2989 43.1997 30.9767C43.0294 30.5266 42.9507 30.0469 42.9682 29.5659C42.9614 29.1691 43.0222 28.774 43.148 28.3975C43.2552 28.0781 43.4256 27.7836 43.6491 27.5315C43.8568 27.2935 44.1169 27.1071 44.409 26.9868C45.0576 26.7407 45.7751 26.7475 46.4189 27.0058C46.7086 27.1303 46.9679 27.3161 47.1788 27.5505C47.3871 27.789 47.5454 28.0669 47.6445 28.3676C47.7531 28.6877 47.8074 29.0237 47.8052 29.3617V30.0916L44.5669 30.0725ZM46.1765 28.9831C46.1759 28.9136 46.1686 28.8443 46.1547 28.7761C46.1403 28.7008 46.1165 28.6276 46.0839 28.5582C46.0278 28.4598 45.9523 28.3737 45.8621 28.3052C45.7719 28.2366 45.6687 28.187 45.5589 28.1593C45.449 28.1316 45.3347 28.1263 45.2227 28.1439C45.1108 28.1614 45.0035 28.2014 44.9074 28.2614C44.7995 28.3399 44.7126 28.4437 44.6541 28.5637C44.6227 28.6336 44.599 28.7066 44.5833 28.7816C44.5703 28.8498 44.564 28.9191 44.5642 28.9886L46.1765 28.9831Z"
            id="filled"
          />
          <path
            d="M62.8661 6.12726V23.0131H1.04199V6.12726H62.8661ZM62.3214 6.67196H1.5867V22.4684H62.3214V6.67196Z"
            id="filled"
          />
          <path
            d="M7.23838 10.218C7.23838 10.218 7.23838 13.5543 7.23838 13.538L10.2479 10.2207H12.6991C12.6991 10.2398 9.12306 14.2216 9.13396 14.2243L12.8516 18.4621H10.4385L7.24111 15.1122V18.4621H5.25293V10.218H7.23838Z"
            id="filled"
          />
          <path
            d="M38.6734 13.5216V10.218H40.6588V18.4621H38.6734V15.1721H34.7597V18.4621H32.7715V10.218L34.7597 10.2207V13.5216H38.6734Z"
            id="filled"
          />
          <path
            d="M48.2512 15.3682L48.2621 10.218H50.2475V18.4621H48.2594L44.4137 13.5026L44.4165 18.4621H42.4229V10.218L44.4165 10.2207L48.2512 15.3682Z"
            id="filled"
          />
          <path
            d="M53.996 11.8684V13.5216H58.6641V15.1721H53.996V16.8198H58.6641V18.4621H52.0078V10.218H58.6641V11.8712L53.996 11.8684Z"
            id="filled"
          />
          <path
            d="M19.9705 15.4254C19.9895 15.4254 21.3322 15.3982 21.3322 14.129C21.3458 14.129 21.3322 11.5171 21.3322 11.509C21.2885 11.1762 21.1338 10.868 20.8931 10.6342C20.6523 10.4004 20.3397 10.2547 20.0059 10.2207H13.8262V18.4649H15.8171V15.5561H17.6909L19.4366 18.4703H21.4711C21.4711 18.4921 19.9868 15.4227 19.9786 15.4281L19.9705 15.4254ZM19.4857 12.7999C19.4857 13.6442 19.3223 13.7913 19.2378 13.8131H15.8143V11.8739H19.2242C19.2242 11.8739 19.4966 11.735 19.4966 12.8026L19.4857 12.7999Z"
            id="filled"
          />
          <path
            d="M29.7724 10.2153H24.3254C23.9974 10.2153 23.6829 10.3455 23.451 10.5774C23.2191 10.8093 23.0889 11.1238 23.0889 11.4518V17.2284C23.0889 17.5563 23.2191 17.8708 23.451 18.1027C23.6829 18.3346 23.9974 18.4648 24.3254 18.4648H29.7724C30.1003 18.4648 30.4149 18.3346 30.6467 18.1027C30.8786 17.8708 31.0089 17.5563 31.0089 17.2284V11.4518C31.0089 11.1238 30.8786 10.8093 30.6467 10.5774C30.4149 10.3455 30.1003 10.2153 29.7724 10.2153ZM29.018 16.8089H25.0879V11.8712H29.018V16.8089Z"
            id="filled"
          />
        </svg>
      </div>
    </div>
  );
};

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    container: css`
      display: flex;
      background-size: cover;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      padding-right: ${theme.spacing.lg};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        background-position: 0px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding-right: ${theme.spacing.sm};
      }
    `,
    title: css``,
    help: css`
      display: flex;
      align-items: baseline;
    `,
    logo: css`
      fill: ${theme.isDark ? 'rgb(204, 204, 220)' : 'rgb(0,93,168)'};
      min-width: 170px;
    `,
  };
});
